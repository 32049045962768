/* \Common\Calendar.css */

.react-calendar {
    max-width: 65%;
    background: #efe9e1;
    border: 2px solid #899481;
    font-family: "Arial", "Helvetica", sans-serif;
    font-size: 0.9rem;
    line-height: 1.1em;
    border-radius: 10px;
    padding: 4px;
    margin: 0 auto;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
}

.react-calendar--doubleView {
    width: 600px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.25em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.25em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

/* Navigation Styles */
.react-calendar__navigation {
    display: flex;
    height: 40px;
    margin-bottom: 0.75em;
}

.react-calendar__navigation button {
    min-width: 40px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #cdbcab;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #11100f;
}

/* Weekday Header Styles */
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.7em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.4em;
}

/* Week Numbers */
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.7em;
    font-weight: bold;
}

/* Day Tile Styles */
.react-calendar__month-view__days__day--weekend {
    color: #5d1c34;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #cdbcab;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1.5em 0.4em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 8px 5px;
    background: none;
    text-align: center;
    font: inherit;
    font-size: 0.75em;
}

.react-calendar__tile:disabled {
    color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #899481;
}

.react-calendar__tile--now {
    background: #cdbcab;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    opacity: 0.8;
}

.react-calendar__tile--hasActive {
    background: #11100f;
    color: #efe9e1;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    opacity: 0.8;
}

.react-calendar__tile--active {
    background: #a67d44;
    color: #efe9e1;
    border: 1.5px solid #a67d44;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background-color: #899481;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #899481;
}
