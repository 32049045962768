/* \User\EditUser.css */

.edit-user-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #efe9e1;
    color: #5d1c34;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.edit-user-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #11100f;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

input,
select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    margin-top: 0.25rem;
}

input:focus,
select:focus {
    border-color: #5d1c34;
    outline: none;
    box-shadow: 0 0 5px rgba(93, 28, 52, 0.5);
}

.h2 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #11100f;
}

/* Buttons */
/* Parent container for buttons */
.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .form-group label {
        font-size: 0.9rem;
    }
}
