/* General Container Styles */
.pricing-container {
    text-align: center;
    padding: 2rem;
    background-color: #efe9e1;
    color: #11100f;
}

.pricing-header {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.pricing-subheader {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #555;
}

/* Pricing Options Styles */
.pricing-footer-container,
.pricing-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.highlight {
    color: #a67d44;
    font-weight: 900;
}

/* Pricing Footer Styles */
.pricing-footer {
    text-align: center;
    padding: 2rem;
}

.pricing-footer-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    gap: 2rem;
}

.footer-card {
    /*  background: #f9f9f9; */
    border: 1px solid #ccc;
    padding: 1.5rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
}
.pricing-icon {
    font-size: 40px;
    margin-bottom: 15px;
}

.footer-card strong {
    display: block;
    font-size: 1.2em;
    margin-top: 10px;
    font-weight: 600;
}

.footer-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #5d1c34;
}

.footer-card p {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #555;
}

.footer-card .price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #a67d44;
    margin-top: 1rem;
}

.footer-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    pricing-footer,
    .pricing-header {
        flex-direction: column;
        align-items: flex-start;
    }
    .pricing-footer-container {
        grid-template-columns: 1fr;
    }
}
