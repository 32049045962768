/* \Common\DashboardCard.css */
.dashboard-card {
    border: 1px solid #cdbcab;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    background-color: #efe9e1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.dashboard-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Card Header */
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.card-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #5d1c34;
}

.card-action {
    margin-left: auto;
}

/* Card Body */
.dashboard-body {
    font-size: 1rem;
    color: #11100f;
    line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
    .dashboard-card {
        padding: 1rem;
    }

    .card-title {
        font-size: 1.25rem;
    }

    .dashboard-body {
        font-size: 0.9rem;
    }
}
