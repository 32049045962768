/* Call to Action Section */
.cta-section {
    background-color: #efe9e1;
    color: #11100f;
    text-align: center;
    padding: 2.5rem 1.5rem;
    border-radius: 10px;
    margin: 1.5rem auto;
    width: 85%;
    max-width: 1000px;
    font-family: "Arial", sans-serif;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.cta-section h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.cta-section p {
    font-size: 1rem;
    margin-bottom: 2rem;
    line-height: 1.4;
    color: #5d1c34;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.cta-button,
.cta-prices {
    display: inline-block;
    font-size: 1rem;
    padding: 0.6rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
}

.cta-button {
    background-color: #5d1c34;
    color: #efe9e1;
}

.cta-prices {
    background-color: #899481;
    color: #efe9e1;
}

.cta-prices:hover,
.cta-button:hover {
    background-color: #cdbcab;
    color: #11100f;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
    .cta-section {
        padding: 2rem 1rem;
    }

    .cta-section h2 {
        font-size: 1.8rem;
    }

    .cta-section p {
        font-size: 0.9rem;
    }

    .cta-button {
        font-size: 0.9rem;
        padding: 0.4rem 1.2rem;
    }
}
