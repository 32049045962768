/* \Event\ViewEvent.css */

.view-event-container {
    max-width: 1000px;
    margin: 1rem auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
    background-color: #efe9e1;
    color: #5d1c34;
}

.view-event-container h1,
.view-event-container p {
    text-align: center;
    margin-bottom: 1rem;
}

.view-event-container p {
    font-size: 1.2rem;
}

.message {
    font-size: 1rem;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 5px;
    text-align: center;
}

.message.success {
    background-color: #dff2bf;
    color: #4f8a10;
    border: 1px solid #4f8a10;
}

.message.error {
    background-color: #f9d3d3;
    color: #802020;
    border: 1px solid #f4a8a8;
}

.action-link {
    height: 3.5rem;
}

.action-link-label {
    max-height: 3.5rem;
    margin-top: 0.5rem;
}

.action-button.btn-danger {
    max-height: 3.5rem;
    margin: 0.5rem 0 0 0 !important;
}

.action-button,
.action-button.btn-danger,
.action-link,
.info-file {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #efe9e1;
    background-color: #899481;
    color: #efe9e1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
    width: fit-content;
    box-sizing: border-box;
}

.action-link-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #899481;
    color: #efe9e1;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.action-button:hover,
.action-link:hover,
.action-link-label:hover,
.info-file:hover {
    background-color: #11100f;
    text-decoration: none;
}

.action-button.btn-danger {
    background-color: #5d1c34;
    color: #f6f6f6;
}

.action-button.btn-danger:hover {
    opacity: 0.8;
}

.modal-button-cancel {
    background-color: #5d1c34;
    color: #f6f6f6;
}

.modal-button-cancel:hover {
    opacity: 0.8;
}

/* Buttons Layout */
.actions-card {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.guest-list {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.guest-list th,
.guest-list td {
    border: 0px solid #eef4ed;
    padding: 8px;
}

.guest-list th {
    background-color: #11100f;
    color: #efe9e1;
}

.guest-list tr:hover {
    background-color: #eef4ed;
}

.no-results {
    font-size: 1rem;
    color: #af1111;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
}

.event-stats-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    justify-items: center; /* Center-align cards */
}

.stats-card {
    max-width: 250px;
    padding: 1.5rem;
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: column; /* Stack content vertically */
    text-align: center;
    width: 100%;
    max-width: 250px; /* Limit the card width for larger screens */
    transition: transform 0.3s ease;
}

.stats-card:hover {
    transform: translateY(-5px); /* Subtle hover effect */
}

.card-icon {
    font-size: 2.5rem;
    color: #899481;
    margin-bottom: 10px; /* Space between icon and content */
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-content h6 {
    font-size: 1rem;
    margin: 0;
    color: #899481;
    font-weight: bold;
}

.card-number {
    font-size: 1.75rem;
    font-weight: bold;
}

.card-number.confirmed {
    color: #9acd32;
}

.card-number.checkedin {
    color: #ff9800;
}

.card-number.loaded {
    color: #73b9d1;
}

/* Responsive Design */
@media (max-width: 480px) {
    .event-stats-cards {
        grid-template-columns: 1fr; /* Stack cards vertically on small screens */
    }

    .stats-card {
        padding: 15px;
    }

    .card-icon {
        font-size: 2rem;
    }

    .card-number {
        font-size: 1.5rem;
    }

    .card-content h6 {
        font-size: 0.9rem;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
    font-size: 1.5rem;
    color: #899481;
}

.modal-content p {
    font-size: 1rem;
    margin-bottom: 20px;
}

/* Modal Buttons */
.modal-content.modal-button-danger {
    padding: 10px;
    margin: 10px auto;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    width: fit-content;
}

.modal-button-danger {
    background-color: #af1111;
    color: #f6f6f6;
}

.modal-button-danger:hover {
    background-color: #850d0d;
}

@media (max-width: 768px) {
    .modal-button-cancel,
    .modal-button-danger {
        width: fit-content;
    }
    .guest-list {
        display: block;
        width: 100%;
        margin: 0 auto;
    }

    .guest-list tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        align-items: center;
    }

    .guest-list td {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem;
        border-bottom: 1px solid #eef4ed;
    }

    .guest-list td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-right: 1rem;
    }

    .no-results {
        text-align: center;
    }
}
