/* Features Section */
.features-section {
    background-color: #ffffff;
    color: #5d1c34;
    padding: 1.5rem 1rem; /* Reduced padding */
    border-radius: 6px; /* Reduced border radius */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Slightly reduced shadow */
    margin: 1.5rem auto; /* Reduced margin */
    width: 90%;
    max-width: 1000px; /* Reduced max-width */
}

.features-section h2 {
    font-size: 1.8rem; /* Reduced font size */
    margin-bottom: 1rem; /* Reduced margin */
    color: #5d1c34;
    text-align: center;
}

.features-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    margin: 0;
}

.features-list li {
    font-size: 1rem; /* Reduced font size */
    margin: 0.5rem; /* Reduced margin */
    display: flex;
    align-items: center;
    gap: 0.8rem; /* Reduced gap */
    background: #efe9e1;
    border-radius: 6px; /* Reduced border radius */
    padding: 1rem; /* Reduced padding */
    flex: 0 1 calc(45% - 1rem);
    min-height: 120px; /* Reduced minimum height */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Reduced shadow */
    transition: transform 0.2s ease-in-out, background 0.2s;
}

.features-list li:hover {
    transform: translateY(-3px); /* Slightly reduced translation */
    background: #cdbcab;
}

.features-list li svg {
    color: #a67d44;
    font-size: 1.8rem; /* Reduced font size */
    flex-shrink: 0;
}

.features-list li:hover svg {
    color: #5d1c34;
}

.features-list li span {
    flex: 1;
}

@media (max-width: 768px) {
    .features-list li {
        flex: 0 1 100%;
        font-size: 0.9rem; /* Further reduced font size */
        min-height: auto;
    }
}
