/* General Dashboard Container */
.dashboard-container {
    width: 100%;
    margin: 0 auto;
    padding: 1rem; /* Reduced padding */
    font-family: Arial, sans-serif;
}

/* Header Section */
.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cdbcab;
    padding-bottom: 0.5rem; /* Added padding */
}

.dashboard-header h2 {
    text-align: center;
    margin-bottom: 0.5rem; /* Reduced margin */
    font-size: 24px; /* Reduced size */
    color: #5d1c34;
}

/* Card Styles */
.dashboard-card {
    background-color: #efe9e1;
    color: #5d1c34;
    border-radius: 8px; /* Reduced radius */
    padding: 15px; /* Reduced padding */
    margin-top: 15px; /* Reduced margin */
    margin-bottom: 1rem; /* Reduced margin */
    box-shadow: 0 2px 4px #cdbcab; /* Reduced shadow */
}

.dashboard-card h1 {
    font-size: 1em; /* Reduced font size */
    font-weight: bold;
}

/* Add Button */
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-card .add-btn {
    background-color: #899481;
    color: #efe9e1;
    font-size: 20px; /* Reduced size */
    width: 35px; /* Reduced size */
    height: 35px; /* Reduced size */
    min-width: 35px; /* Reduced size */
    min-height: 35px; /* Reduced size */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.dashboard-card .add-btn:hover {
    filter: brightness(0.8);
}

.dashboard-body {
    margin: 10px; /* Reduced margin */
}

.dashboard-card p,
.dashboard-card ul {
    font-size: 0.9em; /* Reduced font size */
    margin: 5px 0; /* Reduced margin */
}

.dashboard-card ul {
    list-style-type: none;
    padding-left: 0;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .dashboard-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .logout-btn {
        margin-top: 5px; /* Reduced margin */
        padding: 0.5rem 1rem; /* Reduced padding */
    }
}

.add-user-button {
    background-color: #11100f;
    color: #efe9e1;
}

.add-user-button:hover {
    filter: brightness(0.8);
}
