main {
    margin: 0 auto;
    padding: 0.5rem; /* Reduced padding */
    max-width: 1200px;
    background-color: #efe9e1;
}

.header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #899481;
    padding: 0.75rem; /* Reduced padding */
    color: #cdbcab;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.header.scrolled {
    border-bottom: 1px solid #a67d44; /* Reduced border thickness */
}
