/* \Pages\Contact.css */
.contact-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #efe9e1;
    border-radius: 8px;
    box-shadow: 0px 4px 8px #899481;
}

.contact-container h2 {
    text-align: center;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.contact-form label {
    font-weight: bold;
    color: #5d1c34;
}

.contact-form input,
.contact-form textarea {
    padding: 0.5rem;
    border: 1px solid #cdbcab;
    border-radius: 4px;
}

.contact-form button {
    padding: 0.7rem;
    background-color: #899481;
    color: #efe9e1;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #11100f;
}

.status-message {
    text-align: center;
    margin-top: 1rem;
    color: #a67d44;
    font-weight: bold;
}
