/* BuyCredits Component */
.buy-credits-container {
    text-align: center;
    padding: 2rem;
}

.credit-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    gap: 2rem;
}

.credit-card {
    background: #f9f9f9;
    border: 1px solid #ccc;
    padding: 1.5rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.card-header {
    margin-bottom: 1rem;
}

.card-body {
    flex-grow: 1;
    margin-bottom: 1rem;
}

.card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.credit-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #5d1c34;
}

.credit-card p {
    font-size: 1.2rem;
    color: #555;
}

.card-footer .price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #a67d44;
    margin-top: 1rem;
}

.btn-buy {
    margin-top: auto;
    align-self: center;
}

.btn-buy,
.btn-register,
.btn-login {
    background-color: #899481;
    color: #efe9e1;
    border-color: #899481;
}
.btn-close {
    background-color: #5d1c34;
    color: #efe9e1;
}

.btn-buy:hover,
.btn-register:hover,
.btn-login:hover,
.btn-close:hover {
    opacity: 0.8;
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #efe9e1;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
}

.modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .credit-options {
        grid-template-columns: 1fr;
    }
}
