.to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #a67d44;
    color: #efe9e1;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 42px !important;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.to-top-button:hover,
.to-top-button:focus {
    opacity: 0.8;
    transform: scale(1.1);
}

.to-top-button:active {
    transform: scale(0.95);
}

@media (max-width: 768px) {
    .to-top-button {
        width: 40px;
        height: 40px;
        font-size: 20px;
        bottom: 15px;
        right: 15px;
    }
}

@media (max-width: 480px) {
    .to-top-button {
        width: 35px;
        height: 35px;
        font-size: 18px;
        bottom: 10px;
        right: 10px;
    }
}
