/* \Event\AddEvent.css */

.add-event-container {
    width: 100%;
    max-width: 500px; /* Reduced size */
    margin: 0 auto;
    padding: 15px; /* Reduced padding */
    border-radius: 5px; /* Reduced radius */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Adjusted shadow */
    background: #efe9e1;
    font-family: Arial, sans-serif;
}

.add-event-container h2 {
    font-size: 1.5rem; /* Reduced size */
    color: #5d1c34;
    margin-bottom: 0.5rem; /* Reduced margin */
    text-align: center;
}

.form-group {
    margin-bottom: 1rem; /* Reduced margin */
}

.form-group label {
    display: block;
    font-size: 0.9rem; /* Reduced font size */
    font-weight: bold;
    color: #5d1c34;
    margin-bottom: 0.25rem; /* Reduced margin */
}

input[type="text"] {
    width: 100%;
    padding: 8px; /* Reduced padding */
    font-size: 0.9rem; /* Reduced font size */
    border: 1px solid #cdbcab;
    border-radius: 3px; /* Reduced radius */
    box-sizing: border-box;
}

input[type="text"]:focus {
    border-color: #5d1c34;
    outline: none;
    box-shadow: 0 0 3px rgba(21, 96, 130, 0.5); /* Adjusted shadow */
}

.form-actions {
    text-align: center;
    margin-top: 0.5rem; /* Reduced margin */
}

.message {
    padding: 8px; /* Reduced padding */
    border-radius: 3px; /* Reduced radius */
    font-size: 0.9rem; /* Reduced font size */
    text-align: center;
    margin-bottom: 0.5rem; /* Reduced margin */
}

.message.success {
    background-color: #cde6cf;
    color: #295f2d;
    border: 1px solid #a8d8b1;
}

.message.error {
    background-color: #f9d3d3;
    color: #802020;
    border: 1px solid #f4a8a8;
}
