/* \Common\StatsBar.css */

.stats-bar-container {
    padding: 10px; /* Reduced padding */
    border-radius: 5px; /* Reduced radius */
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(
        auto-fit,
        minmax(120px, 1fr)
    ); /* Reduced minimum size */
    gap: 10px; /* Reduced gap */
}

.stats-card {
    background: #efe9e1;
    padding: 10px; /* Reduced padding */
    border-radius: 5px; /* Reduced radius */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Reduced shadow */
    display: flex;
    align-items: center;
    gap: 5px; /* Reduced gap */
    text-align: center;
    flex-direction: column;
}

.stats-card.no-show .stats-number,
.stats-card.no-show .stats-icon,
.stats-card.no-show h4 {
    color: #5d1c34;
}

.stats-icon {
    font-size: 20px; /* Reduced size */
    color: #a67d44;
}

.stats-number {
    margin: 0;
    font-size: 24px; /* Reduced font size */
    font-weight: bold;
    color: #899481;
}

.progress-bar {
    background: #cdbcab;
    border-radius: 3px; /* Reduced radius */
    overflow: hidden;
    height: 4px; /* Reduced height */
    margin-top: 3px; /* Reduced margin */
    width: 100%;
}

.progress-fill {
    background: #a67d44;
    height: 100%;
    transition: width 0.3s ease;
}

.percentage-label {
    font-size: 12px; /* Reduced size */
    color: #5d1c34;
    font-weight: bold;
}
