/* \Common\Breadcrumbs.module.css */

.breadcrumbs {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem; /* Reduced padding */
    font-size: 0.85rem; /* Reduced font size */
    background-color: #5d1c34;
    color: #efe9e1;
}

.breadcrumbLink {
    color: #cdbcab;
    text-decoration: none;
}

.breadcrumbLink:hover {
    color: #a67d44;
}

.activeBreadcrumb {
    color: #f6f6f6;
    font-weight: bold;
}

.separator {
    color: #dcdcdc;
    margin: 0 0.25rem; /* Reduced margin */
}

.breadcrumbLink:focus {
    outline: 2px solid #a67d44;
    outline-offset: 2px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .breadcrumbs {
        flex-wrap: wrap;
        font-size: 0.6rem; /* Reduced font size */
    }
}

@media (max-width: 468px) {
    .breadcrumbs {
        display: none; /* Hide breadcrumbs for mobile screens */
    }
}
