/* \User\Login.css */
.login-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    background: #efe9e1;
    color: #5d1c34;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Form inputs styling */
input {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

/* Button styling */
.btn-submit:disabled {
    background-color: #888;
    cursor: not-allowed;
}

/* Error and success messages */
.error {
    color: #a67d44;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.success {
    color: #4f8a10;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

/* Register link styling */
p {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #555;
}

p a {
    color: #11100f;
    text-decoration: none;
    font-weight: bold;
}

p a:hover {
    text-decoration: underline;
}

.password-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-container input {
    flex: 1;
    padding-right: 2.5rem; /* Space for the toggle icon */
}

.toggle-password {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    transform: translateY(-50%);
}

.toggle-password.icon {
    font-size: 1.2rem;
    pointer-events: none;
}
