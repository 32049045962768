/* \Guest\GuestTable.css */

.guest-table-container {
    width: 100%;
    overflow-x: auto;
    margin: 0.5rem 0;
}

.guest-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: #efe9e1;
    border-radius: 8px;
    overflow: hidden;
}

.guest-table th,
.guest-table td {
    text-align: left;
    padding: 0.5rem;
    border-bottom: 0px solid #cdbcab;
    white-space: nowrap;
}

.guest-table th {
    background-color: #899481;
    color: #efe9e1;
    font-weight: bold;
    text-transform: uppercase;
}

.guest-table tbody tr:hover {
    background-color: #899481;
    cursor: pointer;
}

.guest-table td {
    color: #11100f;
}

.guest-table td::before {
    display: none; /* Hidden in larger screens, enabled for mobile */
}

/* No Results Message */
.no-results {
    text-align: center;
    color: #5d1c34;
    font-size: 1rem;
    padding: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .guest-table {
        display: block; /* Convert table to block layout */
    }

    .guest-table thead {
        display: none; /* Hide table headers */
    }

    .guest-table tbody tr {
        display: flex;
        flex-direction: column; /* Stack cells vertically */
        margin-bottom: 1rem;
        border: 1px solid #cdbcab;
        border-radius: 8px;
        padding: 0.5rem;
    }

    .guest-table td {
        display: flex;
        justify-content: space-between; /* Align label and content */
        padding: 0.5rem;
        border: none;
        width: 100%; /* Ensure full-width cells */
    }

    .guest-table td::before {
        display: block;
        font-weight: bold;
        margin-right: 0.5rem;
        color: #11100f;
        content: attr(data-label); /* Add label text dynamically */
    }

    .no-results {
        text-align: center;
        font-size: 1rem;
        padding: 1rem;
    }
}

/* Small Devices */
@media (max-width: 480px) {
    .guest-table tbody tr {
        padding: 0.5rem;
    }

    .guest-table td {
        padding: 0.5rem;
        font-size: 0.9rem; /* Reduce text size for smaller screens */
    }
}

.sortable {
    cursor: pointer;
    position: relative;
}

.sortable::after {
    content: " ";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 8px;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #ccc transparent transparent transparent;
}

.sortable.descending::after {
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #ccc transparent;
}
