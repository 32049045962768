/* \User\Register.css */
.register-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    background-color: #efe9e1;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #5d1c34;
    text-align: center;
}

/* Input fields styling */
input {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

/* Button styling */
.btn-submit:disabled {
    background-color: #888;
    cursor: not-allowed;
}

/* Link and text styling */
p {
    margin-top: 15px;
    font-size: 0.9rem;
    color: #555;
}

p a {
    color: #11100f;
    text-decoration: none;
    font-weight: bold;
}

p a:hover {
    text-decoration: underline;
}

/* Error and success messages */
.message {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
}

.message.error {
    color: #a67d44;
    background-color: #ffbaba;
}

.message.success {
    color: #4f8a10;
    background-color: #dff2bf;
}

.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-input-container input {
    flex: 1;
    padding-right: 2.5rem;
}

.toggle-password {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    transform: translateY(-50%);
    color: #888;
}

.toggle-password.icon {
    font-size: 1.2rem;
    pointer-events: none;
}

.password-strength {
    font-weight: bold;
    font-size: 0.9rem;
}
