.guest-panel-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #899481;
    color: #fff;
    border-bottom: 1px solid #cdbcab;
}

.guest-panel-body {
    padding: 1rem;
    overflow-y: auto;
}

.close-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
}

.close-btn:hover {
    opacity: 0.8;
}

.message {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
}

.message.success {
    background-color: #a5d6a7;
    color: #2e7d32;
}

.message.error {
    background-color: #ef9a9a;
    color: #c62828;
}

.alert {
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.375rem;
}

.alert-warning {
    background-color: #efe9e1;
    color: #5d1c34;
    border-color: #5d1c34;
}
