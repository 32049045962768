/* \User\AddUser.css */

.add-user-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #efe9e1;
    color: #5d1c34;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.add-user-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #11100f;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

input,
select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 0.25rem;
}

input:focus,
select:focus {
    border-color: #5d1c34;
    outline: none;
    box-shadow: 0 0 5px rgba(93, 28, 52, 0.5);
}

.h2 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #11100f;
}

/* Buttons */
/* Parent container for buttons */
.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem; /* Consistent spacing between buttons */
}

/* Responsive Design */
@media (max-width: 768px) {
    .edit-user-container {
        padding: 1.5rem;
    }

    .form-group label {
        font-size: 0.9rem;
    }
}

.password-group {
    position: relative;
}

.password-group input {
    flex: 1;
    padding-right: 2.5rem;
}

.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.toggle-password {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    transform: translateY(-50%);
    color: #888;
}

.toggle-password.icon {
    font-size: 1.2rem;
    pointer-events: none;
}

.password-strength {
    font-weight: bold;
    font-size: 0.9rem;
}
