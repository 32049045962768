/* \Common\DataTable.css */
.table-container {
    overflow-x: auto;
    margin-bottom: 0.5rem; /* Reduced margin */
}

.table {
    width: 100%;
    border-collapse: collapse;
    color: #5d1c34;
    border: 1px solid #cdbcab;
    border-radius: 12px; /* Reduced border radius */
}

.table th,
.table td {
    padding: 8px 10px; /* Reduced padding */
    text-align: left;
    color: #11100f;
}

.table th {
    background-color: #cdbcab;
}

.table td {
    background-color: #efe9e1;
}

.actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem; /* Reduced gap */
}

.action-btn {
    padding: 4px 8px; /* Reduced padding */
    font-size: 0.75rem; /* Reduced font size */
    border: none;
    border-radius: 3px; /* Slightly reduced border radius */
    color: #efe9e1;
    cursor: pointer;
}

.action-view {
    background-color: #a67d44;
}

.action-edit {
    background-color: #899481;
}

.action-delete {
    background-color: #5d1c34;
}

/* Hover and focus states */
.action-btn:hover {
    transform: scale(1.03); /* Subtle zoom effect */
    opacity: 0.8;
}

.action-btn:focus {
    outline: none;
    box-shadow: 0 0 3px rgba(21, 96, 130, 0.8); /* Slightly reduced shadow */
}

/* Responsive Design */
@media (max-width: 768px) {
    .table-container {
        border: none;
        margin-bottom: 0.3rem; /* Further reduced margin */
    }

    .table th,
    .table td {
        font-size: 0.8rem; /* Reduced font size */
        padding: 6px 8px; /* Further reduced padding */
    }

    .actions-container {
        flex-wrap: wrap;
    }
}
