/* \User\EmailVerification.css */

.verification-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    background-color: #efe9e1;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #899481;
}

/* Heading style */
.verification-container h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #11100f;
}

/* Feedback messages */
.verification-container .error,
.verification-container .success {
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 15px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

/* Error message styling */
.verification-container .error {
    color: #a67d44;
    background-color: #ffbaba;
    border: 1px solid #a67d44;
}

/* Success message styling */
.verification-container .success {
    color: #4f8a10;
    background-color: #dff2bf;
    border: 1px solid #4f8a10;
}
