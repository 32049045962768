/* /Commmon/Spinner.css */
.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loader {
    width: 80px; /* Reduced width */
    height: 16px; /* Reduced height */
    border-radius: 30px; /* Reduced radius */
    color: #514b82;
    border: 2px solid;
    position: relative;
}

.loader::before {
    content: "";
    position: absolute;
    margin: 2px;
    width: 20%; /* Reduced size */
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background: currentColor;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    50% {
        left: 100%;
        transform: translateX(calc(-100% - 4px));
    }
}
