/* \Guest\GuestEdit.css */

.guest-edit-container {
    max-width: 600px; /* Set a max-width for responsiveness */
    margin: 2rem auto; /* Center horizontally and add top/bottom spacing */
    padding: 2rem;
    border-radius: 5px;
    background: #efe9e1;
    color: #11100f;
    border: 1px solid #cdbcab;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    backdrop-filter: blur(6.2px);
    -webkit-backdrop-filter: blur(6.2px);
}

.guest-edit-container h2 {
    text-align: center;
    font-size: 1.75rem;
    color: #5d1c34;
    margin-bottom: 1rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Add spacing between form elements */
}

label {
    font-size: 1rem;
    font-weight: bold;
    color: #5d1c34;
}

input {
    width: 100%; /* Make inputs full-width */
    padding: 0.25rem;
    font-size: 1rem;
    border: 1px solid #eef4ed;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

input:focus {
    border-color: #a67d44;
    outline: none;
    box-shadow: 0 0 5px rgba(21, 96, 130, 0.4);
}

/* Make it responsive */
@media (max-width: 768px) {
    .guest-edit-container {
        width: 90%; /* Reduce width for smaller screens */
        padding: 1.5rem;
    }

    form {
        gap: 0.75rem; /* Slightly reduce spacing between form elements */
    }

    label {
        font-size: 0.9rem;
    }

    input {
        padding: 0.65rem; /* Adjust padding for smaller inputs */
        font-size: 0.9rem;
    }
}
