/* Hero Section */
.hero-section {
    background-color: #efe9e1;
    color: #11100f;
    text-align: center;
    padding: 3rem 1.5rem; /* Reduced padding */
    border-radius: 10px; /* Reduced border radius */
    margin: 1.5rem auto; /* Reduced margin */
    width: 90%;
    max-width: 1000px; /* Reduced max-width */
    font-family: "Arial", sans-serif;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Slightly reduced shadow */
    animation: fadeIn 1s ease-in-out;
}

.hero-section h1 {
    font-size: 2.5rem; /* Reduced font size */
    font-weight: bold;
    margin-bottom: 0.8rem; /* Reduced margin */
    color: #5d1c34;
}

.hero-section h2 {
    font-size: 1.6rem; /* Reduced font size */
    margin-bottom: 1rem; /* Reduced margin */
    color: #a67d44;
}

.hero-section p {
    font-size: 1rem; /* Reduced font size */
    line-height: 1.4; /* Reduced line-height */
    max-width: 700px; /* Reduced max-width */
    margin: 0 auto 1.5rem; /* Reduced margin */
    color: #899481;
}

.hero-section h1:hover,
.hero-section h2:hover {
    color: #cdbcab;
}

@media (max-width: 768px) {
    .hero-section {
        padding: 2rem 1rem; /* Reduced padding */
    }

    .hero-section h1 {
        font-size: 2rem; /* Reduced font size */
    }

    .hero-section h2 {
        font-size: 1.5rem; /* Reduced font size */
    }

    .hero-section p {
        font-size: 0.9rem; /* Further reduced font size */
    }
}
