/* \src\index.css */

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Lato:wght@400;700&display=swap");

/* General */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Raleway", sans-serif;
    background: #efe9e1;
    color: #11100f;
    min-height: 100vh;
    line-height: 1.6;
}

/* Links */
a {
    color: #a67d44;
    text-decoration: none;
    font-weight: bold;
}

.wrapper {
    margin: 0 auto;
    padding: 1rem 10px;
    max-width: 70rem;
    text-align: center;
}

/* Buttons */
button {
    padding: 1rem 1.35rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.5rem;
    transition: background-color 0.3s ease;
    font-size: 1rem;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    max-width: fit-content;
}

.btn-submit {
    background-color: #899481;
    color: #efe9e1;
}

.btn-danger {
    background-color: #11100f;
    color: #efe9e1;
}

.btn-info {
    background-color: #efe9e1;
    color: #899481;
    border-color: #899481;
}

.btn-cancel {
    background-color: #5d1c34;
    color: #efe9e1;
}

.btn-submit:hover,
.btn-danger:hover,
.btn-info:hover,
.btn-cancel:hover {
    opacity: 0.8;
}

/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
}

th,
td {
    border: 1px solid #cdbcab;
    padding: 0.75rem;
    text-align: left;
}

th {
    background-color: #5d1c34;
    color: #efe9e1;
}

tr:nth-child(even) {
    background-color: #cdbcab;
}

/* Typography */
h1,
h2,
h3,
h4 {
    color: #11100f;
    margin-bottom: 1rem;
}

p {
    margin-bottom: 1rem;
}

h2 {
    font-size: clamp(1.5rem, 2vw, 2rem);
}

h1 {
    font-size: clamp(2rem, 4vw, 3rem);
}

input,
select {
    padding: 0.75rem;
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 1rem;
}

/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(17, 16, 15, 0.8);
    z-index: 1000;
}

.modal-content {
    background: #efe9e1;
    color: #5d1c34;
    padding: 2rem;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .wrapper {
        padding: 1rem;
    }

    button {
        width: 100%;
        padding: 0.5rem;
    }

    table {
        display: block;
        overflow-x: auto;
    }

    th,
    td {
        font-size: 0.9rem;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .modal-content {
        width: 95%;
        padding: 1rem;
    }

    button {
        padding: 0.5rem;
    }

    h1 {
        font-size: 1.75rem;
    }

    h2 {
        font-size: 1.25rem;
    }
}
