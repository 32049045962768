/* General Container Styles */
.getting-started-container {
    padding: 2rem;
    background-color: #efe9e1;
    color: #11100f;
    font-family: "Arial", sans-serif;
    line-height: 1.6;
}

.getting-started-header {
    font-size: 2.5rem;
    color: #5d1c34;
    text-align: center;
    margin-bottom: 1rem;
}

.getting-started-intro {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #555;
}

/* Steps Section */
.steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.step-card {
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.step-card h3 {
    font-size: 1.5rem;
    color: #5d1c34;
    margin-bottom: 1rem;
}

.step-card p {
    font-size: 1.1rem;
    color: #555;
}

/* Quick Tips Section */
.tips-section {
    margin-top: 3rem;
    background: #899481;
    color: #efe9e1;
    padding: 2rem;
    border-radius: 8px;
    text-align: left;
}

.tips-section h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-align: left;
}

.tips-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tips-section li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

.tips-section .tip-icon {
    color: #a67d44;
    margin-right: 0.5rem; /* Adds space between the icon and text */
    font-size: 1.2rem;
}
