/* \Guest\GuestModal.css */

/* GuestModal.css */
.modal .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
}

.modal .message.success {
    background-color: #a67d44;
    color: #11100f;
}

.modal .message.error {
    background-color: #5d1c34;
    color: #efe9e1;
}

.alert-warning {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #a67d44;
    color: #11100f;
}

.guest-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #11100f;
    margin-bottom: 15px;
}

.guest-details p {
    margin: 8px 0;
    text-align: left;
    color: #11100f;
    font-size: 1rem;
}

/* Badge Styles */
.badge {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 0.9rem;
}

.bg-success {
    color: #a67d44 !important;
    border: 1px solid #a67d44;
    padding: 10px 20px;
}

.bg-warning {
    background-color: #efe9e1;
    border: 1px solid #5d1c34;
    padding: 10px 20px;
    color: #5d1c34 !important;
}

.action-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
}

.action-buttons button {
    flex: 1 1 calc(30% - 5px);
    max-width: 48%;
    padding: 10px 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    display: inline-flex;
    transition: filter 0.3s ease;
}

.mdl-close {
    background-color: #11100f;
    color: #efe9e1;
}

.mdl-close:hover,
.mdl-close:focus,
.mdl-close.focus,
.mdl-close:active,
.mdl-close.active {
    filter: brightness(0.5);
}

.mdl-success {
    background-color: #899481;
    color: #11100f;
}

.mdl-success:hover,
.mdl-success:focus,
.mdl-success.focus,
.mdl-success:active,
.mdl-success.active {
    filter: brightness(0.5);
}

.mdl-edit {
    background-color: #cdbcab;
    border-color: #cdbcab;
    color: #f6f6f6;
}

.mdl-edit:hover,
.mdl-edit:focus,
.mdl-edit.focus,
.mdl-edit:active,
.mdl-edit.active {
    filter: brightness(0.5);
}

.mdl-warning {
    background-color: #a67d44;
    border-color: #a67d44;
    color: #11100f;
}

.mdl-warning:hover,
.mdl-warning:focus,
.mdl-warning.focus,
.mdl-warning:active,
.mdl-warning.active {
    filter: brightness(0.5);
}

.mdl-danger {
    background-color: #5d1c34;
    border-color: #5d1c34;
    color: #efe9e1;
}

.mdl-danger:hover,
.mdl-danger:focus,
.mdl-danger.focus,
.mdl-danger:active,
.mdl-danger.active {
    filter: brightness(0.5);
}

.mdl-close,
.mdl-success,
.mdl-edit,
.mdl-warning,
.mdl-danger {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.25rem;
    transition: background-color 0.3s ease;
    justify-content: center;
    font-size: 14px;
    max-width: max-content;
    color: #f6f6f6;
}

button:disabled {
    display: none;
}

@media (max-width: 768px) {
    .view-table {
        overflow-x: auto; /* Add horizontal scrolling */
    }

    .guest-table {
        display: block; /* Stack table for smaller screens */
        width: 100%;
    }

    .guest-table thead {
        display: none; /* Hide table headers */
    }

    .guest-table tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .guest-table td {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;
        border-bottom: 1px solid #eef4ed;
    }

    .guest-table td::before {
        content: attr(data-label); /* Add labels from table headers */
        font-weight: bold;
        margin-right: 1rem;
    }
}

@media (max-width: 468px) {
    .modal {
        overflow-x: auto;
    }
    .action-buttons button {
        flex: 1 1 100%; /* Full-width buttons on smaller screens */
        max-width: 100%;
    }
}
