/* \Guest\CheckInGuests.css */

.checkin-guests-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.checkin-guests-container.panel-open .main-content {
    display: flex;
    justify-content: space-between;
}

.main-content {
    display: flex;
    gap: 1rem;
}

.guest-table-container {
    flex: 3;
}

.guest-panel {
    flex: 1; /* Adjusts to share space with table */
    background-color: #fdfdfd;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    max-width: 400px;
}

.guest-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cdbcab;
    margin-bottom: 1rem;
}

.guest-panel-header h3 {
    font-size: 1.5rem;
    color: #5d1c34;
    margin: 0;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #5d1c34;
    cursor: pointer;
}

.close-btn:hover {
    opacity: 0.8;
}

.guest-panel-body p {
    margin: 0.5rem 0;
    color: #11100f;
}

.guest-panel-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
}

.guest-panel-actions button {
    flex: 1 1 calc(50% - 0.5rem); /* Two buttons per row */
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.panel-open .guest-panel {
    transform: translateX(0);
}

/* @media (max-width: 768px) {
    .guest-panel {
        width: 80%;
    }
} */

.checkin-guests-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: #5d1c34;
}

.checkin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
}

.checkin-header .btn-info {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    background-color: #899481;
    color: #efe9e1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.checkin-header .btn-info:hover {
    background-color: #11100f;
    transform: scale(1.05);
}

.search-bar {
    position: relative; /* Allows the clear button to be positioned inside the input */
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.search-bar input {
    flex: 1;
    padding-right: 2.5rem;
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.search-bar input:focus {
    outline: none;
    background-color: #efe9e1;
    box-shadow: 0 0 5px rgba(137, 148, 129, 0.5);
}

.clear-btn {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    cursor: pointer;
    font-size: 1rem;
    padding: 0;
    margin: 0;
    line-height: 1;
    transform: translateY(-50%);
    background: none;
    border: none;
}

.clear-btn:hover {
    color: #5d1c34;
}

.clear-btn.icon {
    font-size: 1.2rem;
    pointer-events: none;
}

/* Guest Table */
.guest-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.guest-table th,
.guest-table td {
    border: 0px solid #cdbcab;
    padding: 10px;
    text-align: left;
}

.guest-table th {
    background-color: #899481;
    color: #efe9e1;
}

.guest-table tr:nth-child(odd) {
    background-color: #efe9e1;
}

.guest-table tr:hover {
    background-color: #cdbcab;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #efe9e1;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 90%;
}

.modal-content h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.modal-content p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #11100f;
}

.modal-content .btn-secondary,
.modal-content .btn-danger {
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-content .btn-secondary {
    background-color: #a67d44;
    color: #11100f;
}

.modal-content .btn-secondary:hover {
    opacity: 0.8;
}

.modal-content .btn-danger {
    background-color: #5d1c34;
    color: #efe9e1;
}

.modal-content .btn-danger:hover {
    opacity: 0.8;
}

/* Responsive Design */
@media (max-width: 768px) {
    .checkin-header {
        flex-direction: column;
        align-items: center;
    }

    .search-bar {
        width: 90%; /* Adjust width for smaller screens */
    }

    .clear-btn {
        right: 5px; /* Adjust spacing on smaller screens */
    }

    .search-bar input {
        width: 90%;
    }

    .guest-table {
        display: block;
        overflow-x: auto;
    }

    .guest-table th,
    .guest-table td {
        white-space: nowrap;
    }
}
