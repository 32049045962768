.edit-event-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background: #efe9e1;
    font-family: Arial, sans-serif;
}

/* Header Styling */
.edit-event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cdbcab;
    margin-bottom: 1rem;
}

.edit-event-header h2 {
    font-size: 1.5rem;
    color: #5d1c34;
}

/* Form Styles */
.form-group {
    margin-bottom: 1.25rem;
}

label {
    font-size: 0.95rem;
    font-weight: bold;
    color: #5d1c34;
    margin-bottom: 0.5rem;
    display: block;
}

input,
textarea,
select {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
    border-color: #5d1c34;
    outline: none;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .edit-event-container {
        padding: 1rem;
    }

    .edit-event-header h2 {
        font-size: 1.4rem;
    }
}

.message {
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1rem;
}

.message.success {
    color: #295f2d;
    background-color: #cde6cf;
    border: 1px solid #a8d8b1;
}

.message.error {
    color: #802020;
    background-color: #f9d3d3;
    border: 1px solid #f4a8a8;
}
