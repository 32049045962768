/* \Common\MainNavigation.module.css */
.navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Logo Container */
.logoContainer {
    display: flex;
    align-items: center;
    margin-right: 0.5rem; /* Reduced margin */
}

.logo {
    height: 40px; /* Reduced size */
    width: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem; /* Reduced padding */
    background-color: #899481;
    color: #efe9e1;
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s ease;
}

.header.scrolled {
    border-bottom: 1px solid #cdbcab; /* Subtle bottom border */
}

.list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navItem {
    display: flex;
    align-items: center;
    margin: 0 0.5rem; /* Reduced margin */
    padding: 0.25rem; /* Reduced padding */
    text-decoration: none;
    color: #efe9e1;
    font-weight: bold;
    cursor: pointer;
    font-size: 0.85rem !important; /* Reduced font size */
    transition: all 0.3s ease;
}

.navItem:hover {
    color: #11100f;
    transform: scale(1.05);
}

.active {
    color: #11100f;
}

.icon {
    margin-right: 0.25rem; /* Reduced margin */
    font-size: 0.85rem; /* Reduced size */
}

.logout {
    color: #5d1c34;
    font-weight: bold;
    cursor: pointer;
}

.logout:hover {
    color: #11100f;
}

.mobileMenuToggle {
    display: none;
    background: none;
    border: none;
    color: #efe9e1;
    font-size: 0.85rem; /* Reduced size */
    cursor: pointer;
    transition: transform 0.3s ease;
}

.mobileMenuToggle:hover {
    transform: rotate(90deg);
}

@media (max-width: 1000px) {
    .mobileMenuToggle {
        display: block;
        margin-right: 0.5rem; /* Reduced margin */
        position: relative;
        z-index: 1001;
    }

    .list {
        display: none;
        flex-direction: column;
        width: 50vw;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #899481;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow */
        overflow: hidden;
        transition: all 0.3s ease;
        z-index: 1000;
    }

    .mobileMenuOpen {
        display: flex;
    }

    .navItem {
        justify-content: flex-start;
        width: 100%;
        padding: 0.75rem; /* Reduced padding */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
    }

    .navItem:hover {
        background-color: #11100f;
        color: #efe9e1;
    }
}

@media (max-width: 480px) {
    .icon {
        font-size: 0.7rem; /* Reduced size */
    }

    .navItem {
        font-size: 0.7rem; /* Reduced size */
        padding: 1rem; /* Slightly reduced padding */
    }

    .list {
        width: 100vw;
    }

    .header {
        padding: 0.5rem 1rem; /* Reduced padding */
    }
}
