@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Lato:wght@400;700&display=swap);
/* \Common\Calendar.css */

.react-calendar {
    max-width: 65%;
    background: #efe9e1;
    border: 2px solid #899481;
    font-family: "Arial", "Helvetica", sans-serif;
    font-size: 0.9rem;
    line-height: 1.1em;
    border-radius: 10px;
    padding: 4px;
    margin: 0 auto;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
}

.react-calendar--doubleView {
    width: 600px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.25em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.25em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

/* Navigation Styles */
.react-calendar__navigation {
    display: flex;
    height: 40px;
    margin-bottom: 0.75em;
}

.react-calendar__navigation button {
    min-width: 40px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #cdbcab;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #11100f;
}

/* Weekday Header Styles */
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.7em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.4em;
}

/* Week Numbers */
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.7em;
    font-weight: bold;
}

/* Day Tile Styles */
.react-calendar__month-view__days__day--weekend {
    color: #5d1c34;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #cdbcab;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1.5em 0.4em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 8px 5px;
    background: none;
    text-align: center;
    font: inherit;
    font-size: 0.75em;
}

.react-calendar__tile:disabled {
    color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #899481;
}

.react-calendar__tile--now {
    background: #cdbcab;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    opacity: 0.8;
}

.react-calendar__tile--hasActive {
    background: #11100f;
    color: #efe9e1;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    opacity: 0.8;
}

.react-calendar__tile--active {
    background: #a67d44;
    color: #efe9e1;
    border: 1.5px solid #a67d44;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background-color: #899481;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #899481;
}

/* \Event\AddEvent.css */

.add-event-container {
    width: 100%;
    max-width: 500px; /* Reduced size */
    margin: 0 auto;
    padding: 15px; /* Reduced padding */
    border-radius: 5px; /* Reduced radius */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Adjusted shadow */
    background: #efe9e1;
    font-family: Arial, sans-serif;
}

.add-event-container h2 {
    font-size: 1.5rem; /* Reduced size */
    color: #5d1c34;
    margin-bottom: 0.5rem; /* Reduced margin */
    text-align: center;
}

.form-group {
    margin-bottom: 1rem; /* Reduced margin */
}

.form-group label {
    display: block;
    font-size: 0.9rem; /* Reduced font size */
    font-weight: bold;
    color: #5d1c34;
    margin-bottom: 0.25rem; /* Reduced margin */
}

input[type="text"] {
    width: 100%;
    padding: 8px; /* Reduced padding */
    font-size: 0.9rem; /* Reduced font size */
    border: 1px solid #cdbcab;
    border-radius: 3px; /* Reduced radius */
    box-sizing: border-box;
}

input[type="text"]:focus {
    border-color: #5d1c34;
    outline: none;
    box-shadow: 0 0 3px rgba(21, 96, 130, 0.5); /* Adjusted shadow */
}

.form-actions {
    text-align: center;
    margin-top: 0.5rem; /* Reduced margin */
}

.message {
    padding: 8px; /* Reduced padding */
    border-radius: 3px; /* Reduced radius */
    font-size: 0.9rem; /* Reduced font size */
    text-align: center;
    margin-bottom: 0.5rem; /* Reduced margin */
}

.message.success {
    background-color: #cde6cf;
    color: #295f2d;
    border: 1px solid #a8d8b1;
}

.message.error {
    background-color: #f9d3d3;
    color: #802020;
    border: 1px solid #f4a8a8;
}

.edit-event-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background: #efe9e1;
    font-family: Arial, sans-serif;
}

/* Header Styling */
.edit-event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cdbcab;
    margin-bottom: 1rem;
}

.edit-event-header h2 {
    font-size: 1.5rem;
    color: #5d1c34;
}

/* Form Styles */
.form-group {
    margin-bottom: 1.25rem;
}

label {
    font-size: 0.95rem;
    font-weight: bold;
    color: #5d1c34;
    margin-bottom: 0.5rem;
    display: block;
}

input,
textarea,
select {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
    border-color: #5d1c34;
    outline: none;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .edit-event-container {
        padding: 1rem;
    }

    .edit-event-header h2 {
        font-size: 1.4rem;
    }
}

.message {
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1rem;
}

.message.success {
    color: #295f2d;
    background-color: #cde6cf;
    border: 1px solid #a8d8b1;
}

.message.error {
    color: #802020;
    background-color: #f9d3d3;
    border: 1px solid #f4a8a8;
}

/* \Event\ViewEvent.css */

.view-event-container {
    max-width: 1000px;
    margin: 1rem auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
    background-color: #efe9e1;
    color: #5d1c34;
}

.view-event-container h1,
.view-event-container p {
    text-align: center;
    margin-bottom: 1rem;
}

.view-event-container p {
    font-size: 1.2rem;
}

.message {
    font-size: 1rem;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 5px;
    text-align: center;
}

.message.success {
    background-color: #dff2bf;
    color: #4f8a10;
    border: 1px solid #4f8a10;
}

.message.error {
    background-color: #f9d3d3;
    color: #802020;
    border: 1px solid #f4a8a8;
}

.action-link {
    height: 3.5rem;
}

.action-link-label {
    max-height: 3.5rem;
    margin-top: 0.5rem;
}

.action-button.btn-danger {
    max-height: 3.5rem;
    margin: 0.5rem 0 0 0 !important;
}

.action-button,
.action-button.btn-danger,
.action-link,
.info-file {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #efe9e1;
    background-color: #899481;
    color: #efe9e1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    box-sizing: border-box;
}

.action-link-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #899481;
    color: #efe9e1;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.action-button:hover,
.action-link:hover,
.action-link-label:hover,
.info-file:hover {
    background-color: #11100f;
    text-decoration: none;
}

.action-button.btn-danger {
    background-color: #5d1c34;
    color: #f6f6f6;
}

.action-button.btn-danger:hover {
    opacity: 0.8;
}

.modal-button-cancel {
    background-color: #5d1c34;
    color: #f6f6f6;
}

.modal-button-cancel:hover {
    opacity: 0.8;
}

/* Buttons Layout */
.actions-card {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.guest-list {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.guest-list th,
.guest-list td {
    border: 0px solid #eef4ed;
    padding: 8px;
}

.guest-list th {
    background-color: #11100f;
    color: #efe9e1;
}

.guest-list tr:hover {
    background-color: #eef4ed;
}

.no-results {
    font-size: 1rem;
    color: #af1111;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
}

.event-stats-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    justify-items: center; /* Center-align cards */
}

.stats-card {
    max-width: 250px;
    padding: 1.5rem;
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: column; /* Stack content vertically */
    text-align: center;
    width: 100%;
    max-width: 250px; /* Limit the card width for larger screens */
    transition: transform 0.3s ease;
}

.stats-card:hover {
    transform: translateY(-5px); /* Subtle hover effect */
}

.card-icon {
    font-size: 2.5rem;
    color: #899481;
    margin-bottom: 10px; /* Space between icon and content */
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-content h6 {
    font-size: 1rem;
    margin: 0;
    color: #899481;
    font-weight: bold;
}

.card-number {
    font-size: 1.75rem;
    font-weight: bold;
}

.card-number.confirmed {
    color: #9acd32;
}

.card-number.checkedin {
    color: #ff9800;
}

.card-number.loaded {
    color: #73b9d1;
}

/* Responsive Design */
@media (max-width: 480px) {
    .event-stats-cards {
        grid-template-columns: 1fr; /* Stack cards vertically on small screens */
    }

    .stats-card {
        padding: 15px;
    }

    .card-icon {
        font-size: 2rem;
    }

    .card-number {
        font-size: 1.5rem;
    }

    .card-content h6 {
        font-size: 0.9rem;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
    font-size: 1.5rem;
    color: #899481;
}

.modal-content p {
    font-size: 1rem;
    margin-bottom: 20px;
}

/* Modal Buttons */
.modal-content.modal-button-danger {
    padding: 10px;
    margin: 10px auto;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
}

.modal-button-danger {
    background-color: #af1111;
    color: #f6f6f6;
}

.modal-button-danger:hover {
    background-color: #850d0d;
}

@media (max-width: 768px) {
    .modal-button-cancel,
    .modal-button-danger {
        width: -moz-fit-content;
        width: fit-content;
    }
    .guest-list {
        display: block;
        width: 100%;
        margin: 0 auto;
    }

    .guest-list tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        align-items: center;
    }

    .guest-list td {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem;
        border-bottom: 1px solid #eef4ed;
    }

    .guest-list td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-right: 1rem;
    }

    .no-results {
        text-align: center;
    }
}

/* \Common\ColumnSettings.css */
.column-settings-container {
    max-width: 600px;
    margin: 2rem auto;
    background-color: #efe9e1;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
}

.column-settings-container h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #5d1c34;
}

.column-settings-table {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    border-collapse: collapse;
}

.column-settings-table td,
.column-settings-table th {
    border: none;
    padding: 8px;
}

/* Checkbox Styles */
.custom-checkbox-input {
    display: none;
}

.checkbox-cell {
    width: 10%;
    text-align: center;
    display: flex;
    align-items: center;
}

.custom-checkbox-input + .custom-checkbox-label {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    cursor: pointer;
}

.custom-checkbox-input + .custom-checkbox-label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #11100f;
    border-radius: 4px;
    background-color: white;
    transition: background-color 0.2s, border-color 0.2s;
}

.custom-checkbox-input:checked + .custom-checkbox-label::before {
    background-color: #899481;
    border-color: #899481;
}

.custom-checkbox-input:checked + .custom-checkbox-label::after {
    content: "\2713";
    position: absolute;
    left: 4px;
    top: -1px;
    font-size: 16px;
    color: white;
}

/* Button Styles */
.save-column-settings-btn {
    background-color: #899481;
    color: #efe9e1;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 20px;
}

.save-column-settings-btn:hover {
    background-color: #efe9e1;
    color: #899481;
    border: 1px solid #899481;
}

/* Status Messages */
.status-message {
    font-weight: bold;
    margin: 1rem 0;
}

.status-message.success {
    color: #a67d44;
}

.status-message.error {
    color: #5d1c34;
}

/* Responsive Design */
@media (max-width: 768px) {
    .column-settings-container {
        padding: 1rem;
    }

    .save-column-settings-btn {
        width: 100%;
        padding: 12px;
    }
}

.add-guest-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1.5rem;
    background: #efe9e1;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.add-guest-container h2 {
    font-size: 1.5rem;
    color: #5d1c34;
    text-align: center;
    margin-bottom: 1.5rem;
}

form {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

label {
    font-size: 0.95rem;
    font-weight: bold;
    color: #5d1c34;
}

input {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #eef4ed;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

input:focus {
    border-color: #5d1c34;
    outline: none;
}

button {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button.btn-info {
    background-color: #899481;
    color: #efe9e1;
}

button.btn-info:hover {
    background-color: #5d1c34;
}

.error {
    font-size: 1rem;
    color: #802020;
    text-align: center;
    background-color: #f9d3d3;
    border: 1px solid #f4a8a8;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .add-guest-container {
        width: 90%;
        padding: 1.5rem;
    }

    input,
    button {
        font-size: 0.9rem;
        padding: 0.65rem;
    }
}

/* \Guest\CheckInGuests.css */

.checkin-guests-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.checkin-guests-container.panel-open .main-content {
    display: flex;
    justify-content: space-between;
}

.main-content {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.guest-table-container {
    flex: 3 1;
}

.guest-panel {
    flex: 1 1; /* Adjusts to share space with table */
    background-color: #fdfdfd;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    max-width: 400px;
}

.guest-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cdbcab;
    margin-bottom: 1rem;
}

.guest-panel-header h3 {
    font-size: 1.5rem;
    color: #5d1c34;
    margin: 0;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #5d1c34;
    cursor: pointer;
}

.close-btn:hover {
    opacity: 0.8;
}

.guest-panel-body p {
    margin: 0.5rem 0;
    color: #11100f;
}

.guest-panel-actions {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-top: 1rem;
}

.guest-panel-actions button {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 0.5rem); /* Two buttons per row */
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.panel-open .guest-panel {
    transform: translateX(0);
}

/* @media (max-width: 768px) {
    .guest-panel {
        width: 80%;
    }
} */

.checkin-guests-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: #5d1c34;
}

.checkin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
}

.checkin-header .btn-info {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    background-color: #899481;
    color: #efe9e1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.checkin-header .btn-info:hover {
    background-color: #11100f;
    transform: scale(1.05);
}

.search-bar {
    position: relative; /* Allows the clear button to be positioned inside the input */
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.search-bar input {
    flex: 1 1;
    padding-right: 2.5rem;
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.search-bar input:focus {
    outline: none;
    background-color: #efe9e1;
    box-shadow: 0 0 5px rgba(137, 148, 129, 0.5);
}

.clear-btn {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    cursor: pointer;
    font-size: 1rem;
    padding: 0;
    margin: 0;
    line-height: 1;
    transform: translateY(-50%);
    background: none;
    border: none;
}

.clear-btn:hover {
    color: #5d1c34;
}

.clear-btn.icon {
    font-size: 1.2rem;
    pointer-events: none;
}

/* Guest Table */
.guest-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.guest-table th,
.guest-table td {
    border: 0px solid #cdbcab;
    padding: 10px;
    text-align: left;
}

.guest-table th {
    background-color: #899481;
    color: #efe9e1;
}

.guest-table tr:nth-child(odd) {
    background-color: #efe9e1;
}

.guest-table tr:hover {
    background-color: #cdbcab;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #efe9e1;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 90%;
}

.modal-content h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.modal-content p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #11100f;
}

.modal-content .btn-secondary,
.modal-content .btn-danger {
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-content .btn-secondary {
    background-color: #a67d44;
    color: #11100f;
}

.modal-content .btn-secondary:hover {
    opacity: 0.8;
}

.modal-content .btn-danger {
    background-color: #5d1c34;
    color: #efe9e1;
}

.modal-content .btn-danger:hover {
    opacity: 0.8;
}

/* Responsive Design */
@media (max-width: 768px) {
    .checkin-header {
        flex-direction: column;
        align-items: center;
    }

    .search-bar {
        width: 90%; /* Adjust width for smaller screens */
    }

    .clear-btn {
        right: 5px; /* Adjust spacing on smaller screens */
    }

    .search-bar input {
        width: 90%;
    }

    .guest-table {
        display: block;
        overflow-x: auto;
    }

    .guest-table th,
    .guest-table td {
        white-space: nowrap;
    }
}

/* \Guest\GuestEdit.css */

.guest-edit-container {
    max-width: 600px; /* Set a max-width for responsiveness */
    margin: 2rem auto; /* Center horizontally and add top/bottom spacing */
    padding: 2rem;
    border-radius: 5px;
    background: #efe9e1;
    color: #11100f;
    border: 1px solid #cdbcab;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    backdrop-filter: blur(6.2px);
    -webkit-backdrop-filter: blur(6.2px);
}

.guest-edit-container h2 {
    text-align: center;
    font-size: 1.75rem;
    color: #5d1c34;
    margin-bottom: 1rem;
}

form {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem; /* Add spacing between form elements */
}

label {
    font-size: 1rem;
    font-weight: bold;
    color: #5d1c34;
}

input {
    width: 100%; /* Make inputs full-width */
    padding: 0.25rem;
    font-size: 1rem;
    border: 1px solid #eef4ed;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

input:focus {
    border-color: #a67d44;
    outline: none;
    box-shadow: 0 0 5px rgba(21, 96, 130, 0.4);
}

/* Make it responsive */
@media (max-width: 768px) {
    .guest-edit-container {
        width: 90%; /* Reduce width for smaller screens */
        padding: 1.5rem;
    }

    form {
        grid-gap: 0.75rem;
        gap: 0.75rem; /* Slightly reduce spacing between form elements */
    }

    label {
        font-size: 0.9rem;
    }

    input {
        padding: 0.65rem; /* Adjust padding for smaller inputs */
        font-size: 0.9rem;
    }
}

/* \Guest\GuestModal.css */

/* GuestModal.css */
.modal .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
}

.modal .message.success {
    background-color: #a67d44;
    color: #11100f;
}

.modal .message.error {
    background-color: #5d1c34;
    color: #efe9e1;
}

.alert-warning {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #a67d44;
    color: #11100f;
}

.guest-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #11100f;
    margin-bottom: 15px;
}

.guest-details p {
    margin: 8px 0;
    text-align: left;
    color: #11100f;
    font-size: 1rem;
}

/* Badge Styles */
.badge {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 0.9rem;
}

.bg-success {
    color: #a67d44 !important;
    border: 1px solid #a67d44;
    padding: 10px 20px;
}

.bg-warning {
    background-color: #efe9e1;
    border: 1px solid #5d1c34;
    padding: 10px 20px;
    color: #5d1c34 !important;
}

.action-buttons {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
}

.action-buttons button {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(30% - 5px);
    max-width: 48%;
    padding: 10px 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    cursor: pointer;
    display: inline-flex;
    transition: filter 0.3s ease;
}

.mdl-close {
    background-color: #11100f;
    color: #efe9e1;
}

.mdl-close:hover,
.mdl-close:focus,
.mdl-close.focus,
.mdl-close:active,
.mdl-close.active {
    filter: brightness(0.5);
}

.mdl-success {
    background-color: #899481;
    color: #11100f;
}

.mdl-success:hover,
.mdl-success:focus,
.mdl-success.focus,
.mdl-success:active,
.mdl-success.active {
    filter: brightness(0.5);
}

.mdl-edit {
    background-color: #cdbcab;
    border-color: #cdbcab;
    color: #f6f6f6;
}

.mdl-edit:hover,
.mdl-edit:focus,
.mdl-edit.focus,
.mdl-edit:active,
.mdl-edit.active {
    filter: brightness(0.5);
}

.mdl-warning {
    background-color: #a67d44;
    border-color: #a67d44;
    color: #11100f;
}

.mdl-warning:hover,
.mdl-warning:focus,
.mdl-warning.focus,
.mdl-warning:active,
.mdl-warning.active {
    filter: brightness(0.5);
}

.mdl-danger {
    background-color: #5d1c34;
    border-color: #5d1c34;
    color: #efe9e1;
}

.mdl-danger:hover,
.mdl-danger:focus,
.mdl-danger.focus,
.mdl-danger:active,
.mdl-danger.active {
    filter: brightness(0.5);
}

.mdl-close,
.mdl-success,
.mdl-edit,
.mdl-warning,
.mdl-danger {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.25rem;
    transition: background-color 0.3s ease;
    justify-content: center;
    font-size: 14px;
    max-width: max-content;
    color: #f6f6f6;
}

button:disabled {
    display: none;
}

@media (max-width: 768px) {
    .view-table {
        overflow-x: auto; /* Add horizontal scrolling */
    }

    .guest-table {
        display: block; /* Stack table for smaller screens */
        width: 100%;
    }

    .guest-table thead {
        display: none; /* Hide table headers */
    }

    .guest-table tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .guest-table td {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;
        border-bottom: 1px solid #eef4ed;
    }

    .guest-table td::before {
        content: attr(data-label); /* Add labels from table headers */
        font-weight: bold;
        margin-right: 1rem;
    }
}

@media (max-width: 468px) {
    .modal {
        overflow-x: auto;
    }
    .action-buttons button {
        flex: 1 1 100%; /* Full-width buttons on smaller screens */
        max-width: 100%;
    }
}

/* \Guest\GuestTable.css */

.guest-table-container {
    width: 100%;
    overflow-x: auto;
    margin: 0.5rem 0;
}

.guest-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: #efe9e1;
    border-radius: 8px;
    overflow: hidden;
}

.guest-table th,
.guest-table td {
    text-align: left;
    padding: 0.5rem;
    border-bottom: 0px solid #cdbcab;
    white-space: nowrap;
}

.guest-table th {
    background-color: #899481;
    color: #efe9e1;
    font-weight: bold;
    text-transform: uppercase;
}

.guest-table tbody tr:hover {
    background-color: #899481;
    cursor: pointer;
}

.guest-table td {
    color: #11100f;
}

.guest-table td::before {
    display: none; /* Hidden in larger screens, enabled for mobile */
}

/* No Results Message */
.no-results {
    text-align: center;
    color: #5d1c34;
    font-size: 1rem;
    padding: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .guest-table {
        display: block; /* Convert table to block layout */
    }

    .guest-table thead {
        display: none; /* Hide table headers */
    }

    .guest-table tbody tr {
        display: flex;
        flex-direction: column; /* Stack cells vertically */
        margin-bottom: 1rem;
        border: 1px solid #cdbcab;
        border-radius: 8px;
        padding: 0.5rem;
    }

    .guest-table td {
        display: flex;
        justify-content: space-between; /* Align label and content */
        padding: 0.5rem;
        border: none;
        width: 100%; /* Ensure full-width cells */
    }

    .guest-table td::before {
        display: block;
        font-weight: bold;
        margin-right: 0.5rem;
        color: #11100f;
        content: attr(data-label); /* Add label text dynamically */
    }

    .no-results {
        text-align: center;
        font-size: 1rem;
        padding: 1rem;
    }
}

/* Small Devices */
@media (max-width: 480px) {
    .guest-table tbody tr {
        padding: 0.5rem;
    }

    .guest-table td {
        padding: 0.5rem;
        font-size: 0.9rem; /* Reduce text size for smaller screens */
    }
}

.sortable {
    cursor: pointer;
    position: relative;
}

.sortable::after {
    content: " ";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 8px;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #ccc transparent transparent transparent;
}

.sortable.descending::after {
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #ccc transparent;
}

.guest-panel-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #899481;
    color: #fff;
    border-bottom: 1px solid #cdbcab;
}

.guest-panel-body {
    padding: 1rem;
    overflow-y: auto;
}

.close-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
}

.close-btn:hover {
    opacity: 0.8;
}

.message {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
}

.message.success {
    background-color: #a5d6a7;
    color: #2e7d32;
}

.message.error {
    background-color: #ef9a9a;
    color: #c62828;
}

.alert {
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.375rem;
}

.alert-warning {
    background-color: #efe9e1;
    color: #5d1c34;
    border-color: #5d1c34;
}

/* \User\AddUser.css */

.add-user-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #efe9e1;
    color: #5d1c34;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.add-user-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #11100f;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

input,
select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 0.25rem;
}

input:focus,
select:focus {
    border-color: #5d1c34;
    outline: none;
    box-shadow: 0 0 5px rgba(93, 28, 52, 0.5);
}

.h2 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #11100f;
}

/* Buttons */
/* Parent container for buttons */
.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem; /* Consistent spacing between buttons */
}

/* Responsive Design */
@media (max-width: 768px) {
    .edit-user-container {
        padding: 1.5rem;
    }

    .form-group label {
        font-size: 0.9rem;
    }
}

.password-group {
    position: relative;
}

.password-group input {
    flex: 1 1;
    padding-right: 2.5rem;
}

.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.toggle-password {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    transform: translateY(-50%);
    color: #888;
}

.toggle-password.icon {
    font-size: 1.2rem;
    pointer-events: none;
}

.password-strength {
    font-weight: bold;
    font-size: 0.9rem;
}

/* \User\EditUser.css */

.edit-user-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #efe9e1;
    color: #5d1c34;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.edit-user-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #11100f;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

input,
select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    margin-top: 0.25rem;
}

input:focus,
select:focus {
    border-color: #5d1c34;
    outline: none;
    box-shadow: 0 0 5px rgba(93, 28, 52, 0.5);
}

.h2 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #11100f;
}

/* Buttons */
/* Parent container for buttons */
.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .form-group label {
        font-size: 0.9rem;
    }
}

/* \User\EmailVerification.css */

.verification-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    background-color: #efe9e1;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #899481;
}

/* Heading style */
.verification-container h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #11100f;
}

/* Feedback messages */
.verification-container .error,
.verification-container .success {
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 15px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

/* Error message styling */
.verification-container .error {
    color: #a67d44;
    background-color: #ffbaba;
    border: 1px solid #a67d44;
}

/* Success message styling */
.verification-container .success {
    color: #4f8a10;
    background-color: #dff2bf;
    border: 1px solid #4f8a10;
}

/* \User\Login.css */
.login-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    background: #efe9e1;
    color: #5d1c34;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Form inputs styling */
input {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

/* Button styling */
.btn-submit:disabled {
    background-color: #888;
    cursor: not-allowed;
}

/* Error and success messages */
.error {
    color: #a67d44;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.success {
    color: #4f8a10;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

/* Register link styling */
p {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #555;
}

p a {
    color: #11100f;
    text-decoration: none;
    font-weight: bold;
}

p a:hover {
    text-decoration: underline;
}

.password-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-container input {
    flex: 1 1;
    padding-right: 2.5rem; /* Space for the toggle icon */
}

.toggle-password {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    transform: translateY(-50%);
}

.toggle-password.icon {
    font-size: 1.2rem;
    pointer-events: none;
}

/* \User\Register.css */
.register-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    background-color: #efe9e1;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #5d1c34;
    text-align: center;
}

/* Input fields styling */
input {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

/* Button styling */
.btn-submit:disabled {
    background-color: #888;
    cursor: not-allowed;
}

/* Link and text styling */
p {
    margin-top: 15px;
    font-size: 0.9rem;
    color: #555;
}

p a {
    color: #11100f;
    text-decoration: none;
    font-weight: bold;
}

p a:hover {
    text-decoration: underline;
}

/* Error and success messages */
.message {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
}

.message.error {
    color: #a67d44;
    background-color: #ffbaba;
}

.message.success {
    color: #4f8a10;
    background-color: #dff2bf;
}

.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-input-container input {
    flex: 1 1;
    padding-right: 2.5rem;
}

.toggle-password {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    transform: translateY(-50%);
    color: #888;
}

.toggle-password.icon {
    font-size: 1.2rem;
    pointer-events: none;
}

.password-strength {
    font-weight: bold;
    font-size: 0.9rem;
}

/* BuyCredits Component */
.buy-credits-container {
    text-align: center;
    padding: 2rem;
}

.credit-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.credit-card {
    background: #f9f9f9;
    border: 1px solid #ccc;
    padding: 1.5rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.card-header {
    margin-bottom: 1rem;
}

.card-body {
    flex-grow: 1;
    margin-bottom: 1rem;
}

.card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.credit-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #5d1c34;
}

.credit-card p {
    font-size: 1.2rem;
    color: #555;
}

.card-footer .price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #a67d44;
    margin-top: 1rem;
}

.btn-buy {
    margin-top: auto;
    align-self: center;
}

.btn-buy,
.btn-register,
.btn-login {
    background-color: #899481;
    color: #efe9e1;
    border-color: #899481;
}
.btn-close {
    background-color: #5d1c34;
    color: #efe9e1;
}

.btn-buy:hover,
.btn-register:hover,
.btn-login:hover,
.btn-close:hover {
    opacity: 0.8;
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #efe9e1;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
}

.modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .credit-options {
        grid-template-columns: 1fr;
    }
}

/* \User\ChangePassword.css */

.change-password-container {
    margin: 0 auto;
    padding: 2rem;
    max-width: 600px;
    background: #efe9e1;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.change-password-container .h2 {
    font-size: 1.75rem;
    color: #5d1c34;
    text-align: center;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.password-input-container {
    position: relative;
}

.password-input-container input {
    width: 100%;
    padding-right: 2.5rem;
    font-size: 1rem;
    border: 1px solid #cdbcab;
    border-radius: 5px;
    box-sizing: border-box;
}

.toggle-password {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #a67d44;
    font-size: 1.25rem;
}

.password-strength {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
}

.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

/* \Common\DashboardCard.css */
.dashboard-card {
    border: 1px solid #cdbcab;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    background-color: #efe9e1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.dashboard-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Card Header */
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.card-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #5d1c34;
}

.card-action {
    margin-left: auto;
}

/* Card Body */
.dashboard-body {
    font-size: 1rem;
    color: #11100f;
    line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
    .dashboard-card {
        padding: 1rem;
    }

    .card-title {
        font-size: 1.25rem;
    }

    .dashboard-body {
        font-size: 0.9rem;
    }
}

/* \Common\DataTable.css */
.table-container {
    overflow-x: auto;
    margin-bottom: 0.5rem; /* Reduced margin */
}

.table {
    width: 100%;
    border-collapse: collapse;
    color: #5d1c34;
    border: 1px solid #cdbcab;
    border-radius: 12px; /* Reduced border radius */
}

.table th,
.table td {
    padding: 8px 10px; /* Reduced padding */
    text-align: left;
    color: #11100f;
}

.table th {
    background-color: #cdbcab;
}

.table td {
    background-color: #efe9e1;
}

.actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.3rem;
    gap: 0.3rem; /* Reduced gap */
}

.action-btn {
    padding: 4px 8px; /* Reduced padding */
    font-size: 0.75rem; /* Reduced font size */
    border: none;
    border-radius: 3px; /* Slightly reduced border radius */
    color: #efe9e1;
    cursor: pointer;
}

.action-view {
    background-color: #a67d44;
}

.action-edit {
    background-color: #899481;
}

.action-delete {
    background-color: #5d1c34;
}

/* Hover and focus states */
.action-btn:hover {
    transform: scale(1.03); /* Subtle zoom effect */
    opacity: 0.8;
}

.action-btn:focus {
    outline: none;
    box-shadow: 0 0 3px rgba(21, 96, 130, 0.8); /* Slightly reduced shadow */
}

/* Responsive Design */
@media (max-width: 768px) {
    .table-container {
        border: none;
        margin-bottom: 0.3rem; /* Further reduced margin */
    }

    .table th,
    .table td {
        font-size: 0.8rem; /* Reduced font size */
        padding: 6px 8px; /* Further reduced padding */
    }

    .actions-container {
        flex-wrap: wrap;
    }
}

/* \Common\MainNavigation.module.css */
.MainNavigation_navContainer__4BMkU {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Logo Container */
.MainNavigation_logoContainer__1D7Ow {
    display: flex;
    align-items: center;
    margin-right: 0.5rem; /* Reduced margin */
}

.MainNavigation_logo__vZ3hq {
    height: 40px; /* Reduced size */
    width: auto;
}

.MainNavigation_header__2WWF0 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem; /* Reduced padding */
    background-color: #899481;
    color: #efe9e1;
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s ease;
}

.MainNavigation_header__2WWF0.MainNavigation_scrolled__17MzQ {
    border-bottom: 1px solid #cdbcab; /* Subtle bottom border */
}

.MainNavigation_list__Z8rnc {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.MainNavigation_navItem__SJA_B {
    display: flex;
    align-items: center;
    margin: 0 0.5rem; /* Reduced margin */
    padding: 0.25rem; /* Reduced padding */
    text-decoration: none;
    color: #efe9e1;
    font-weight: bold;
    cursor: pointer;
    font-size: 0.85rem !important; /* Reduced font size */
    transition: all 0.3s ease;
}

.MainNavigation_navItem__SJA_B:hover {
    color: #11100f;
    transform: scale(1.05);
}

.MainNavigation_active__2gEv6 {
    color: #11100f;
}

.MainNavigation_icon__edg-O {
    margin-right: 0.25rem; /* Reduced margin */
    font-size: 0.85rem; /* Reduced size */
}

.MainNavigation_logout__2wXLj {
    color: #5d1c34;
    font-weight: bold;
    cursor: pointer;
}

.MainNavigation_logout__2wXLj:hover {
    color: #11100f;
}

.MainNavigation_mobileMenuToggle__2iF9_ {
    display: none;
    background: none;
    border: none;
    color: #efe9e1;
    font-size: 0.85rem; /* Reduced size */
    cursor: pointer;
    transition: transform 0.3s ease;
}

.MainNavigation_mobileMenuToggle__2iF9_:hover {
    transform: rotate(90deg);
}

@media (max-width: 1000px) {
    .MainNavigation_mobileMenuToggle__2iF9_ {
        display: block;
        margin-right: 0.5rem; /* Reduced margin */
        position: relative;
        z-index: 1001;
    }

    .MainNavigation_list__Z8rnc {
        display: none;
        flex-direction: column;
        width: 50vw;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #899481;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow */
        overflow: hidden;
        transition: all 0.3s ease;
        z-index: 1000;
    }

    .MainNavigation_mobileMenuOpen__jWyYC {
        display: flex;
    }

    .MainNavigation_navItem__SJA_B {
        justify-content: flex-start;
        width: 100%;
        padding: 0.75rem; /* Reduced padding */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
    }

    .MainNavigation_navItem__SJA_B:hover {
        background-color: #11100f;
        color: #efe9e1;
    }
}

@media (max-width: 480px) {
    .MainNavigation_icon__edg-O {
        font-size: 0.7rem; /* Reduced size */
    }

    .MainNavigation_navItem__SJA_B {
        font-size: 0.7rem; /* Reduced size */
        padding: 1rem; /* Slightly reduced padding */
    }

    .MainNavigation_list__Z8rnc {
        width: 100vw;
    }

    .MainNavigation_header__2WWF0 {
        padding: 0.5rem 1rem; /* Reduced padding */
    }
}

.to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #a67d44;
    color: #efe9e1;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 42px !important;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.to-top-button:hover,
.to-top-button:focus {
    opacity: 0.8;
    transform: scale(1.1);
}

.to-top-button:active {
    transform: scale(0.95);
}

@media (max-width: 768px) {
    .to-top-button {
        width: 40px;
        height: 40px;
        font-size: 20px;
        bottom: 15px;
        right: 15px;
    }
}

@media (max-width: 480px) {
    .to-top-button {
        width: 35px;
        height: 35px;
        font-size: 18px;
        bottom: 10px;
        right: 10px;
    }
}

/* \Common\Breadcrumbs.module.css */

.Breadcrumbs_breadcrumbs__2cqpP {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem; /* Reduced padding */
    font-size: 0.85rem; /* Reduced font size */
    background-color: #5d1c34;
    color: #efe9e1;
}

.Breadcrumbs_breadcrumbLink__b2FGa {
    color: #cdbcab;
    text-decoration: none;
}

.Breadcrumbs_breadcrumbLink__b2FGa:hover {
    color: #a67d44;
}

.Breadcrumbs_activeBreadcrumb__33cN9 {
    color: #f6f6f6;
    font-weight: bold;
}

.Breadcrumbs_separator__DxcGJ {
    color: #dcdcdc;
    margin: 0 0.25rem; /* Reduced margin */
}

.Breadcrumbs_breadcrumbLink__b2FGa:focus {
    outline: 2px solid #a67d44;
    outline-offset: 2px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .Breadcrumbs_breadcrumbs__2cqpP {
        flex-wrap: wrap;
        font-size: 0.6rem; /* Reduced font size */
    }
}

@media (max-width: 468px) {
    .Breadcrumbs_breadcrumbs__2cqpP {
        display: none; /* Hide breadcrumbs for mobile screens */
    }
}

main {
    margin: 0 auto;
    padding: 0.5rem; /* Reduced padding */
    max-width: 1200px;
    background-color: #efe9e1;
}

.RootLayout_header__2Cr4M {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #899481;
    padding: 0.75rem; /* Reduced padding */
    color: #cdbcab;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.RootLayout_header__2Cr4M.RootLayout_scrolled__3Vd6p {
    border-bottom: 1px solid #a67d44; /* Reduced border thickness */
}

/* \Common\StatsBar.css */

.stats-bar-container {
    padding: 10px; /* Reduced padding */
    border-radius: 5px; /* Reduced radius */
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(
        auto-fit,
        minmax(120px, 1fr)
    ); /* Reduced minimum size */
    grid-gap: 10px;
    gap: 10px; /* Reduced gap */
}

.stats-card {
    background: #efe9e1;
    padding: 10px; /* Reduced padding */
    border-radius: 5px; /* Reduced radius */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Reduced shadow */
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px; /* Reduced gap */
    text-align: center;
    flex-direction: column;
}

.stats-card.no-show .stats-number,
.stats-card.no-show .stats-icon,
.stats-card.no-show h4 {
    color: #5d1c34;
}

.stats-icon {
    font-size: 20px; /* Reduced size */
    color: #a67d44;
}

.stats-number {
    margin: 0;
    font-size: 24px; /* Reduced font size */
    font-weight: bold;
    color: #899481;
}

.progress-bar {
    background: #cdbcab;
    border-radius: 3px; /* Reduced radius */
    overflow: hidden;
    height: 4px; /* Reduced height */
    margin-top: 3px; /* Reduced margin */
    width: 100%;
}

.progress-fill {
    background: #a67d44;
    height: 100%;
    transition: width 0.3s ease;
}

.percentage-label {
    font-size: 12px; /* Reduced size */
    color: #5d1c34;
    font-weight: bold;
}

/* /Commmon/Spinner.css */
.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loader {
    width: 80px; /* Reduced width */
    height: 16px; /* Reduced height */
    border-radius: 30px; /* Reduced radius */
    color: #514b82;
    border: 2px solid;
    position: relative;
}

.loader::before {
    content: "";
    position: absolute;
    margin: 2px;
    width: 20%; /* Reduced size */
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background: currentColor;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    50% {
        left: 100%;
        transform: translateX(calc(-100% - 4px));
    }
}

/* \Pages\Contact.css */
.contact-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #efe9e1;
    border-radius: 8px;
    box-shadow: 0px 4px 8px #899481;
}

.contact-container h2 {
    text-align: center;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.contact-form {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}
.contact-form label {
    font-weight: bold;
    color: #5d1c34;
}

.contact-form input,
.contact-form textarea {
    padding: 0.5rem;
    border: 1px solid #cdbcab;
    border-radius: 4px;
}

.contact-form button {
    padding: 0.7rem;
    background-color: #899481;
    color: #efe9e1;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #11100f;
}

.status-message {
    text-align: center;
    margin-top: 1rem;
    color: #a67d44;
    font-weight: bold;
}

/* General Dashboard Container */
.dashboard-container {
    width: 100%;
    margin: 0 auto;
    padding: 1rem; /* Reduced padding */
    font-family: Arial, sans-serif;
}

/* Header Section */
.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cdbcab;
    padding-bottom: 0.5rem; /* Added padding */
}

.dashboard-header h2 {
    text-align: center;
    margin-bottom: 0.5rem; /* Reduced margin */
    font-size: 24px; /* Reduced size */
    color: #5d1c34;
}

/* Card Styles */
.dashboard-card {
    background-color: #efe9e1;
    color: #5d1c34;
    border-radius: 8px; /* Reduced radius */
    padding: 15px; /* Reduced padding */
    margin-top: 15px; /* Reduced margin */
    margin-bottom: 1rem; /* Reduced margin */
    box-shadow: 0 2px 4px #cdbcab; /* Reduced shadow */
}

.dashboard-card h1 {
    font-size: 1em; /* Reduced font size */
    font-weight: bold;
}

/* Add Button */
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-card .add-btn {
    background-color: #899481;
    color: #efe9e1;
    font-size: 20px; /* Reduced size */
    width: 35px; /* Reduced size */
    height: 35px; /* Reduced size */
    min-width: 35px; /* Reduced size */
    min-height: 35px; /* Reduced size */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.dashboard-card .add-btn:hover {
    filter: brightness(0.8);
}

.dashboard-body {
    margin: 10px; /* Reduced margin */
}

.dashboard-card p,
.dashboard-card ul {
    font-size: 0.9em; /* Reduced font size */
    margin: 5px 0; /* Reduced margin */
}

.dashboard-card ul {
    list-style-type: none;
    padding-left: 0;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .dashboard-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .logout-btn {
        margin-top: 5px; /* Reduced margin */
        padding: 0.5rem 1rem; /* Reduced padding */
    }
}

.add-user-button {
    background-color: #11100f;
    color: #efe9e1;
}

.add-user-button:hover {
    filter: brightness(0.8);
}

/* Hero Section */
.hero-section {
    background-color: #efe9e1;
    color: #11100f;
    text-align: center;
    padding: 3rem 1.5rem; /* Reduced padding */
    border-radius: 10px; /* Reduced border radius */
    margin: 1.5rem auto; /* Reduced margin */
    width: 90%;
    max-width: 1000px; /* Reduced max-width */
    font-family: "Arial", sans-serif;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Slightly reduced shadow */
    animation: fadeIn 1s ease-in-out;
}

.hero-section h1 {
    font-size: 2.5rem; /* Reduced font size */
    font-weight: bold;
    margin-bottom: 0.8rem; /* Reduced margin */
    color: #5d1c34;
}

.hero-section h2 {
    font-size: 1.6rem; /* Reduced font size */
    margin-bottom: 1rem; /* Reduced margin */
    color: #a67d44;
}

.hero-section p {
    font-size: 1rem; /* Reduced font size */
    line-height: 1.4; /* Reduced line-height */
    max-width: 700px; /* Reduced max-width */
    margin: 0 auto 1.5rem; /* Reduced margin */
    color: #899481;
}

.hero-section h1:hover,
.hero-section h2:hover {
    color: #cdbcab;
}

@media (max-width: 768px) {
    .hero-section {
        padding: 2rem 1rem; /* Reduced padding */
    }

    .hero-section h1 {
        font-size: 2rem; /* Reduced font size */
    }

    .hero-section h2 {
        font-size: 1.5rem; /* Reduced font size */
    }

    .hero-section p {
        font-size: 0.9rem; /* Further reduced font size */
    }
}

/* Features Section */
.features-section {
    background-color: #ffffff;
    color: #5d1c34;
    padding: 1.5rem 1rem; /* Reduced padding */
    border-radius: 6px; /* Reduced border radius */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Slightly reduced shadow */
    margin: 1.5rem auto; /* Reduced margin */
    width: 90%;
    max-width: 1000px; /* Reduced max-width */
}

.features-section h2 {
    font-size: 1.8rem; /* Reduced font size */
    margin-bottom: 1rem; /* Reduced margin */
    color: #5d1c34;
    text-align: center;
}

.features-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    margin: 0;
}

.features-list li {
    font-size: 1rem; /* Reduced font size */
    margin: 0.5rem; /* Reduced margin */
    display: flex;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem; /* Reduced gap */
    background: #efe9e1;
    border-radius: 6px; /* Reduced border radius */
    padding: 1rem; /* Reduced padding */
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(45% - 1rem);
    min-height: 120px; /* Reduced minimum height */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Reduced shadow */
    transition: transform 0.2s ease-in-out, background 0.2s;
}

.features-list li:hover {
    transform: translateY(-3px); /* Slightly reduced translation */
    background: #cdbcab;
}

.features-list li svg {
    color: #a67d44;
    font-size: 1.8rem; /* Reduced font size */
    flex-shrink: 0;
}

.features-list li:hover svg {
    color: #5d1c34;
}

.features-list li span {
    flex: 1 1;
}

@media (max-width: 768px) {
    .features-list li {
        flex: 0 1 100%;
        font-size: 0.9rem; /* Further reduced font size */
        min-height: auto;
    }
}

/* Call to Action Section */
.cta-section {
    background-color: #efe9e1;
    color: #11100f;
    text-align: center;
    padding: 2.5rem 1.5rem;
    border-radius: 10px;
    margin: 1.5rem auto;
    width: 85%;
    max-width: 1000px;
    font-family: "Arial", sans-serif;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.cta-section h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.cta-section p {
    font-size: 1rem;
    margin-bottom: 2rem;
    line-height: 1.4;
    color: #5d1c34;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.cta-button,
.cta-prices {
    display: inline-block;
    font-size: 1rem;
    padding: 0.6rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
}

.cta-button {
    background-color: #5d1c34;
    color: #efe9e1;
}

.cta-prices {
    background-color: #899481;
    color: #efe9e1;
}

.cta-prices:hover,
.cta-button:hover {
    background-color: #cdbcab;
    color: #11100f;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
    .cta-section {
        padding: 2rem 1rem;
    }

    .cta-section h2 {
        font-size: 1.8rem;
    }

    .cta-section p {
        font-size: 0.9rem;
    }

    .cta-button {
        font-size: 0.9rem;
        padding: 0.4rem 1.2rem;
    }
}

/* General Container Styles */
.getting-started-container {
    padding: 2rem;
    background-color: #efe9e1;
    color: #11100f;
    font-family: "Arial", sans-serif;
    line-height: 1.6;
}

.getting-started-header {
    font-size: 2.5rem;
    color: #5d1c34;
    text-align: center;
    margin-bottom: 1rem;
}

.getting-started-intro {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #555;
}

/* Steps Section */
.steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.step-card {
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.step-card h3 {
    font-size: 1.5rem;
    color: #5d1c34;
    margin-bottom: 1rem;
}

.step-card p {
    font-size: 1.1rem;
    color: #555;
}

/* Quick Tips Section */
.tips-section {
    margin-top: 3rem;
    background: #899481;
    color: #efe9e1;
    padding: 2rem;
    border-radius: 8px;
    text-align: left;
}

.tips-section h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-align: left;
}

.tips-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tips-section li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

.tips-section .tip-icon {
    color: #a67d44;
    margin-right: 0.5rem; /* Adds space between the icon and text */
    font-size: 1.2rem;
}

/* General Container Styles */
.pricing-container {
    text-align: center;
    padding: 2rem;
    background-color: #efe9e1;
    color: #11100f;
}

.pricing-header {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #5d1c34;
}

.pricing-subheader {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #555;
}

/* Pricing Options Styles */
.pricing-footer-container,
.pricing-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin-bottom: 2rem;
}

.highlight {
    color: #a67d44;
    font-weight: 900;
}

/* Pricing Footer Styles */
.pricing-footer {
    text-align: center;
    padding: 2rem;
}

.pricing-footer-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.footer-card {
    /*  background: #f9f9f9; */
    border: 1px solid #ccc;
    padding: 1.5rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
}
.pricing-icon {
    font-size: 40px;
    margin-bottom: 15px;
}

.footer-card strong {
    display: block;
    font-size: 1.2em;
    margin-top: 10px;
    font-weight: 600;
}

.footer-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #5d1c34;
}

.footer-card p {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #555;
}

.footer-card .price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #a67d44;
    margin-top: 1rem;
}

.footer-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    pricing-footer,
    .pricing-header {
        flex-direction: column;
        align-items: flex-start;
    }
    .pricing-footer-container {
        grid-template-columns: 1fr;
    }
}

/* \src\index.css */

/* General */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Raleway", sans-serif;
    background: #efe9e1;
    color: #11100f;
    min-height: 100vh;
    line-height: 1.6;
}

/* Links */
a {
    color: #a67d44;
    text-decoration: none;
    font-weight: bold;
}

.wrapper {
    margin: 0 auto;
    padding: 1rem 10px;
    max-width: 70rem;
    text-align: center;
}

/* Buttons */
button {
    padding: 1rem 1.35rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.5rem;
    transition: background-color 0.3s ease;
    font-size: 1rem;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.btn-submit {
    background-color: #899481;
    color: #efe9e1;
}

.btn-danger {
    background-color: #11100f;
    color: #efe9e1;
}

.btn-info {
    background-color: #efe9e1;
    color: #899481;
    border-color: #899481;
}

.btn-cancel {
    background-color: #5d1c34;
    color: #efe9e1;
}

.btn-submit:hover,
.btn-danger:hover,
.btn-info:hover,
.btn-cancel:hover {
    opacity: 0.8;
}

/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
}

th,
td {
    border: 1px solid #cdbcab;
    padding: 0.75rem;
    text-align: left;
}

th {
    background-color: #5d1c34;
    color: #efe9e1;
}

tr:nth-child(even) {
    background-color: #cdbcab;
}

/* Typography */
h1,
h2,
h3,
h4 {
    color: #11100f;
    margin-bottom: 1rem;
}

p {
    margin-bottom: 1rem;
}

h2 {
    font-size: clamp(1.5rem, 2vw, 2rem);
}

h1 {
    font-size: clamp(2rem, 4vw, 3rem);
}

input,
select {
    padding: 0.75rem;
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 1rem;
}

/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(17, 16, 15, 0.8);
    z-index: 1000;
}

.modal-content {
    background: #efe9e1;
    color: #5d1c34;
    padding: 2rem;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .wrapper {
        padding: 1rem;
    }

    button {
        width: 100%;
        padding: 0.5rem;
    }

    table {
        display: block;
        overflow-x: auto;
    }

    th,
    td {
        font-size: 0.9rem;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .modal-content {
        width: 95%;
        padding: 1rem;
    }

    button {
        padding: 0.5rem;
    }

    h1 {
        font-size: 1.75rem;
    }

    h2 {
        font-size: 1.25rem;
    }
}

