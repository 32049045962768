.add-guest-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1.5rem;
    background: #efe9e1;
    border: 1px solid #cdbcab;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.add-guest-container h2 {
    font-size: 1.5rem;
    color: #5d1c34;
    text-align: center;
    margin-bottom: 1.5rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

label {
    font-size: 0.95rem;
    font-weight: bold;
    color: #5d1c34;
}

input {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #eef4ed;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

input:focus {
    border-color: #5d1c34;
    outline: none;
}

button {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button.btn-info {
    background-color: #899481;
    color: #efe9e1;
}

button.btn-info:hover {
    background-color: #5d1c34;
}

.error {
    font-size: 1rem;
    color: #802020;
    text-align: center;
    background-color: #f9d3d3;
    border: 1px solid #f4a8a8;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .add-guest-container {
        width: 90%;
        padding: 1.5rem;
    }

    input,
    button {
        font-size: 0.9rem;
        padding: 0.65rem;
    }
}
