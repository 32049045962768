/* \Common\ColumnSettings.css */
.column-settings-container {
    max-width: 600px;
    margin: 2rem auto;
    background-color: #efe9e1;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
}

.column-settings-container h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #5d1c34;
}

.column-settings-table {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    border-collapse: collapse;
}

.column-settings-table td,
.column-settings-table th {
    border: none;
    padding: 8px;
}

/* Checkbox Styles */
.custom-checkbox-input {
    display: none;
}

.checkbox-cell {
    width: 10%;
    text-align: center;
    display: flex;
    align-items: center;
}

.custom-checkbox-input + .custom-checkbox-label {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    cursor: pointer;
}

.custom-checkbox-input + .custom-checkbox-label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #11100f;
    border-radius: 4px;
    background-color: white;
    transition: background-color 0.2s, border-color 0.2s;
}

.custom-checkbox-input:checked + .custom-checkbox-label::before {
    background-color: #899481;
    border-color: #899481;
}

.custom-checkbox-input:checked + .custom-checkbox-label::after {
    content: "\2713";
    position: absolute;
    left: 4px;
    top: -1px;
    font-size: 16px;
    color: white;
}

/* Button Styles */
.save-column-settings-btn {
    background-color: #899481;
    color: #efe9e1;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 20px;
}

.save-column-settings-btn:hover {
    background-color: #efe9e1;
    color: #899481;
    border: 1px solid #899481;
}

/* Status Messages */
.status-message {
    font-weight: bold;
    margin: 1rem 0;
}

.status-message.success {
    color: #a67d44;
}

.status-message.error {
    color: #5d1c34;
}

/* Responsive Design */
@media (max-width: 768px) {
    .column-settings-container {
        padding: 1rem;
    }

    .save-column-settings-btn {
        width: 100%;
        padding: 12px;
    }
}
